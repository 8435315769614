.innerUsers,
.innerUsersRecipe {
  max-width: 400px;
  margin: 0 16px 60px;
  min-width: 350px;
}
.innerUsersRecipe {
  margin-top: 67px;
}

.titleTableUsers {
  font-family: 'Rubik-Bold';
  font-size: 48px;
  line-height: 74px;
  margin-bottom: 32px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: 100%;
}

.wrapperUsers {
  display: flex;
  width: 348px;
  margin: 0 auto;
  align-items: center;
  flex-wrap: wrap;
}
.groupUserPhotoMargin:nth-child(4) {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.groupUserPhotoMargin:nth-child(5) {
  display: flex;
  width: 50%;
}
.groupUserPhoto {
  width: 100px;
  height: 100px;
  box-shadow: 0 4px 9px 0 #00000040;
  border-radius: 42px;
  object-fit: cover;
}

.groupUserPhotoMargin {
  padding: 8px 8px 10px;
}

.userWithoutPhoto {
  display: flex;
  align-items: center;
  width: 100px;
  height: 100px;
  justify-content: center;
  background-image: url('../../assets/background/userWithoutPhoto.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.userTableName {
  font-family: 'Rubik-Medium';
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  margin-top: -7px;
  max-width: 64px;
  color: #37190c;
  white-space: pre-line;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inviteUser {
  width: 100px !important;
  height: 100px;
  border-radius: 42px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  background-image: url('../../assets/background/inviteBackground.png');
  background-position: center;
  background-size: cover;
}

.inviteUser:hover {
  opacity: 0.5;
}

.inviteText {
  font-family: 'CourierPrime-Bold';
  font-size: 15px;
  line-height: 19px;
  color: #37190c;
}

.advertisingDesktop,
.advertisingMobile {
  width: 100%;
  height: 400px;
  object-fit: contain;
  margin-top: 70px;
}

.advertisingMobile {
  display: none;
}

.sliderUsers {
  padding: 0 20px;
  display: none;
}

.sliderUsers .groupUserPhoto {
  margin: 8px 8px 10px;
}

@media (max-width: 991px) {
  .userWithoutPhoto {
    background-size: 70px;
  }
  .innerUsers,
  .innerUsersRecipe {
    min-width: 320px;
  }
  .groupUserPhotoMargin {
    padding: 0;
    margin: 8px 5px 20px;
  }
  .innerUsersRecipe {
    display: none;
  }
  .userTableName {
    font-size: 12px;
    max-width: 48px;
  }
  .groupUserPhoto {
    width: 65px;
    height: 65px;
    min-width: 65px;
    border-radius: 26px;
  }
  .advertisingMobile {
    display: block;
    height: 200px;
    margin-top: 30px;
  }
  .advertisingDesktop {
    display: none;
  }
  .innerUsers {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
  }
  .titleTableUsers {
    font-size: 24px;
    line-height: 43px;
    margin-bottom: 15px;
  }

  .inviteUser {
    width: 65px !important;
    height: 65px;
    border-radius: 26px;
  }
  .inviteText {
    font-size: 12px;
  }
  .wrapperUsers {
    display: none;
  }
  .sliderUsers {
    display: block;
  }
  .groupUserPhotoMargin {
    margin: 8px 8px 10px;
    padding: 0;
  }
}
