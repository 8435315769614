.innerInviteFam {
  max-width: 770px;
  margin: 0 auto;
}

.innerListFam {
  width: 100%;
}

.innerListFam input {
  font-family: 'CourierPrime-Regular';
  font-size: 24px;
  line-height: 19px;
  padding: 0 20px;
}

.innerListFam input::placeholder {
  color: #e0e0e0;
}

.innerInviteFam .textInviteGroup {
  margin: 50px 0 30px;
}

.textDropDownInvite {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inviteLink {
  white-space: break-spaces;
}

.iconCopyText {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

@media (max-width: 991px) {
  .innerInviteFam .textInviteGroup {
    margin: 30px 0 15px;
  }
  .innerListFam input {
    font-size: 16px;
    line-height: 18px;
    height: 40px;
  }
  .innerInviteFam .submitAdd {
    height: 40px;
  }
  .dropDownArray {
    font-size: 16px;
    line-height: 18px;
    height: 43px;
  }
}
