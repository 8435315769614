.titleChangePassword {
  margin-bottom: 60px;
  margin-top: 102px;
}

.innerChangePassword {
  max-width: 627px;
  width: 100%;
  margin: 0 auto;
}

.inputChangePassword {
  margin-bottom: 26px;
}

.inputChangePassword input {
  text-align: center;
}

.inputChangePassword:first-child {
  margin-bottom: 80px;
}

.inputChangePassword input::placeholder {
  text-align: center;
  color: #e0e0e0;
  font-size: 24px;
  line-height: 36px;
}

.inputChangePassword:nth-child(3) {
  margin-bottom: 80px;
}

@media (max-width: 991px) {
  .titleChangePassword {
    margin-top: 35px;
  }
  .inputChangePassword {
    margin-bottom: 13px;
  }
  .inputChangePassword:first-child {
    margin-bottom: 40px;
  }
  .inputChangePassword input {
    font-size: 18px;
    line-height: 20px;
    height: 40px;
  }
  .inputChangePassword input::placeholder {
    font-size: 18px;
    line-height: 20px;
  }

  .inputChangePassword:nth-child(3) {
    margin-bottom: 30px;
  }
}
