.modalReportDropWrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 56px 0;
  flex-direction: row;
  flex-wrap: wrap;
}
.modalReportTitle {
  font-family: 'Rubik-Medium';
  max-width: 510px;
  text-align: center;
  margin: 0 auto;
  font-size: 36px;
  line-height: 36px;
  color: #000;
}
.dropdownReportGlobal {
  max-width: 365px;
}
.modalButton {
  height: 63px;
  max-width: 375px;
  width: 100%;
  margin-top: 24px;
}
.reportButton {
  background-color: #ef2127;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: auto;
  margin-right: auto;
}
.reportButton:hover {
  background-color: #ba1a1f;
}
.modalReportText {
  max-width: 280px;
  width: 100%;
  color: #000;
  font-family: Rubik-Regular, sans-serif;
  font-size: 24px;
  line-height: 36px;
}
.reportDropDownTitle {
  width: 320px;
  overflow: hidden;
}
.reportDropDown {
  padding-right: 55px;
  max-width: 385px !important;
  width: 100%;
}

.modalTextArea {
  max-height: 160px;
  height: 160px;
  font-size: 24px;
  line-height: 36px;
}
/*Modal Image*/
.innerImageScreen {
  width: 100%;
  height: 100%;
}
.fullScreenImg {
  max-height: 100vh;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.innerModalImage .modal-content {
  background-color: #000000;
}
.modal-content .closeImg {
  top: 10px;
  right: 10px;
  background-color: #ffffff;
  color: #37190c;
}
/*Report*/
.modalReportDialog {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1078px;
  width: 100%;
  border-radius: 14px;
}

.modalReportContent {
  margin: 16px;
}

.innerFormReport {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px 112px;
}

.modal-body {
  box-shadow: 2px 3px 5px 1px #c9c9c9;
  border-radius: 14px;
}
.modalClose {
  position: absolute;
  cursor: pointer;
  top: -13px;
  right: -16px;
  z-index: 20;
  width: 41px;
  height: 41px;
  border-radius: 22px;
  background-color: #37190c;
  color: #f7f5ad;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Rubik-Regular, sans-serif;
  font-size: 24px;
}

.backgroundTrial {
  backdrop-filter: blur(8px);
  background-color: transparent;
  opacity: 1 !important;
}

@media (max-width: 991px) {
  .dropdownReportGlobal {
    max-width: initial;
  }
  .modalReportDropWrap {
    margin: 25px 0;
  }
  .reportDropDown {
    max-width: initial !important;
    font-size: 20px;
    line-height: 30px;
    height: 46px;
  }
  .modalReportTitle {
    font-size: 24px;
    line-height: 30px;
  }
  .modalReportText {
    font-size: 20px;
    line-height: 30px;
  }
  .modalTextArea {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 430px) {
  .modalClose {
    right: 0;
  }
}
