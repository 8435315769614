.innerFooter {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 50;
  display: none;
}

.footer {
  padding: 12px 0 15px;
  background-color: #37190c;
  justify-content: center;
  position: sticky;
  display: flex;
  bottom: 0;
  z-index: 50;
}

.footerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
  width: 100%;
}

.btnAddRecipe {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  background: #fec925;
  border: 3px solid #37190c;
  margin-top: -25px;
  border-radius: 50%;
}

.btnAddRecipe span {
  display: block;
  width: 20px;
  border-radius: 3px;
  height: 3px;
  background-color: #37190c;
  position: relative;
}

.btnAddRecipe span:first-child {
  transform: rotate(90deg);
  left: 10px;
}

.btnAddRecipe span:nth-child(2) {
  right: 10px;
}

.navigateGroups {
  cursor: pointer;
}

@media (max-width: 991px) {
  .innerFooter {
    display: block;
  }
  .innerGroupMenu {
    position: fixed;
    background-color: #f7f5ad;
    overflow-y: scroll;
    bottom: -100%;
    width: 100%;
    z-index: 30;
    left: 0;
    transition: all 0.5s;
    max-height: 225px;
  }

  .activeGroupMenu {
    bottom: 68px;
  }
}
