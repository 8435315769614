.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: none;
}
.form-check-input[type='checkbox'] {
  border: 3px solid #e0e0e0;
  border-radius: 5px;
  margin-top: 0;
  width: 22px;
  height: 22px;
}
.borderError {
  border: 3px solid red !important;
}
.form-check-input:checked[type='checkbox'] {
  background-image: none;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #57ad91;
}
.markBrown.form-check-input.is-valid:checked,
.markBrown.was-validated .form-check-input:valid:checked {
  background-color: #37190c;
}

.markBrown:checked {
  background-color: #37190c;
}
