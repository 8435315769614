.containerMain {
  max-width: 1704px;
  padding: 0 10px;
  margin: 0 auto 68px;
}
.dashboardWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 0.5s ease-out;
}

.profileVideo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-right: 50px;
  margin-top: 60px;
  grid-column: auto / span 10;
}
.textEmptyRecipes {
  font-family: 'Rubik-Bold';
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  margin-bottom: 20px;
  max-width: 726px;
}
.textEmptyRecipes span {
  display: block;
}
.profileVideo video {
  max-width: 750px;
  width: 100%;
}
.blurModal {
  filter: blur(8px);
  transition: all 0.5s ease-out;
}
.backgroundRecipeStyle {
  width: 100%;
  height: 422px;
  object-fit: cover;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-end;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.recipeWrap {
  max-width: 1190px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.recipeWrapContent {
  padding: 0 16px;
}
.backgroundShadowRecipe {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 4px 8px 0 #00000040;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: rgba(0, 0, 0, 0.24);
}
.recipeHeader {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.recipeHeaderTitle {
  font-family: Rubik-Bold, sans-serif;
  font-size: 64px;
  line-height: 61px;
  max-width: 100%;
  color: #f7f5ad;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
  padding: 20px 10px 5px 10px;
  overflow-wrap: anywhere;
  margin-bottom: 16px;
}
.recipeHeaderSetting {
  min-width: 205px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 20px 10px;
  color: #f7f5ad;
}

.recipeHeaderSetting > a {
  color: #f7f5ad;
  white-space: nowrap;
}

.recipeText {
  font-size: 24px;
  line-height: 36px;
  margin-top: 19px;
  font-family: CourierPrime-Regular, sans-serif;
}

.recipeWrapStep {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
.recipeIngredients {
  max-width: 320px;
  width: 100%;
}
.recipeLine {
  width: 4px;
  margin: 40px 60px 0 80px;
  border-radius: 4px;
  background-color: rgba(55, 25, 12, 0.25);
}
.recipeSteps {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 87px;
}

.reportWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 64px;
  transition: all 0.3s ease-out;
}
.reportText {
  padding-left: 16px;
  font-size: 18px;
  line-height: 36px;
  font-family: CourierPrime-Regular, sans-serif;
  color: red;
  text-decoration: underline;
}

.innerInfoCreatorUser {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 17px 9px;
}

.infoCreatorUserText {
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 14px;
  color: #f7f5ad;
  text-align: center;
}
.infoCreatorUserPhoto {
  width: 63px;
  height: 63px;
  border-radius: 35px;
}
.infoCreatorUser {
  font-family: CourierPrime-Bold, sans-serif;
  color: #f7f5ad;
  text-align: center;
  margin-bottom: 5px;
}

.modal-content {
  border-radius: 14px;
}
.recipeSummaryWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.innerDescription {
  max-width: 830px;
  width: 100%;
}
.textDescription:first-letter {
  text-transform: capitalize;
}
.summaryAvatarWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 33px 16px 16px;
  max-width: 256px;
  width: 100%;
}
.summaryAvatar {
  width: 111px;
  height: 111px;
  border-radius: 47px;
  margin-bottom: 12px;
}
.summaryAvatarText {
  font-size: 14px;
  font-family: CourierPrime-Regular, sans-serif;
}
.summaryAvatarName {
  font-size: 14px;
  font-family: CourierPrime-Bold, sans-serif;
}
.recipeStepContent {
  position: relative;
  padding-left: 48px;
  font-size: 24px;
  line-height: 36px;
  font-family: CourierPrime-Regular, sans-serif;
}
.recipeStepContent:first-child {
  margin-top: 19px;
}
.recipeNumber {
  position: absolute;
  left: 0;
}
@media (max-width: 1300px) {
  .recipeWrapStep {
    flex-direction: column;
  }
  .recipeSteps {
    padding-right: 0;
  }
  .recipeLine {
    display: none;
  }
}
.microText {
  margin-top: 60px;
  margin-bottom: 11px;
}
.recipeWrapContent .recipeTitle {
  color: #893f1d;
  margin-top: 40px;
}

@media (max-width: 1300px) {
  .profileVideo {
    grid-column: auto / span 12;
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .profileVideo {
    margin: 0;
    padding: 0 16px;
  }
  .textEmptyRecipes {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .profileVideo video {
    margin-top: 15px;
  }
  .summaryAvatarWrap {
    display: none;
  }
  .recipeWrapContent .recipeTitle {
    margin-top: 68px;
  }
  .backgroundRecipeStyle {
    height: 230px;
  }
  .dashboardWrap {
    flex-direction: column;
  }
  .recipeSteps {
    padding-left: 0;
    padding-right: 0;
    border-left: none;
  }

  .recipeTitle {
    font-size: 48px;
    line-height: 43px;
  }

  .recipeHeaderTitle {
    font-size: 30px;
    line-height: 36px;
    max-width: 500px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 20px 10px;
    padding: 0;
  }
  .recipeHeaderSetting {
    min-width: 150px;
  }
  .innerInfoCreatorUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
