.innerNavigationAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 33px auto 0;
  max-width: 437px;
  width: 100%;
}

.linkNavigationAccount {
  font-family: 'Rubik-Bold';
  font-size: 24px;
  line-height: 43px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #57ad91;
  border-radius: 5px;
  padding: 27px 0;
  margin: 13px 0;
  color: #ffffff;
}

.linkNavigationAccount:hover {
  color: #ffffff;
}

.navigationArrowSettings {
  margin-left: 16px;
}

.styleLogout {
  font-family: 'Rubik-Bold';
  font-size: 24px;
  line-height: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #37190c;
  margin: 13px 0;
}
.styleLogout:hover {
  color: #37190c;
}
@media (max-width: 991px) {
  .linkNavigationAccount {
    padding: 10px 0;
    font-size: 20px;
  }
}
@media (max-width: 573px) {
  .linkNavigationAccount {
    line-height: 40px;
    margin: 10px 0;
    max-width: 300px;
  }
}
