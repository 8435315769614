.innerLoadingPage {
  flex-direction: column;
  z-index: 100000;
}

.innerLoadingPage .headerText {
  max-width: 450px;
  text-align: center;
  margin-top: 20px;
}

.loadingLogo {
  animation-name: loading;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  max-width: 200px;
  width: 100%;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1.5);
  }
}
