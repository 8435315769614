.innerTextPage {
  font-family: 'CourierPrime-Regular';
  font-size: 36px;
  line-height: 50px;
  color: #37190c;
  height: 475px;
  position: relative;
  max-width: 569px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.wrapperSubscription .innerTextPage {
  height: auto;
  max-width: 800px;
}
.innerTextPage span {
  font-weight: 700;
}

.manageTextBack {
  position: absolute;
  left: 0;
  bottom: 0;
}

.dropdownTypeSubscription {
  margin-bottom: 15px;
}

.innerSubscriptionPage {
  height: 200px;
  padding-top: 20px;
}

.linkManageSubscriptions {
  max-width: 437px;
  margin: 0 auto;
  display: block;
  border: none;
  cursor: pointer;
}

.buttonManageSubscribe {
  max-width: 300px;
  width: 100%;
  background-color: #57ad91;
  padding: 20px 25px;
  border-radius: 10px;
  color: #ffffff;
  transition: all 0.5s;
}

.buttonManageSubscribe:hover {
  background-color: #4b937c;
  color: #ffffff;
}

.removePaddingTop {
  padding-top: 0 !important;
}

.innerTypeSubscribe {
  margin-bottom: 15px;
}

.titleWithoutMargin {
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .innerTextPage {
    font-size: 18px;
    line-height: 24px;
    height: 265px;
  }
  .innerSubscriptionPage {
    padding-top: 30px;
    height: initial;
  }
  .titleWithoutMargin {
    margin-bottom: 0;
  }
}
