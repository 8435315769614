.headerContainer {
  max-width: 1610px;
  margin: 0 auto;
  padding: 0 16px;
}

.header {
  padding: 20px 0;
  background-color: #37190c;
  position: sticky;
  top: 0;
  z-index: 50;
}

.headerLogo {
  max-width: 120px;
  width: 100%;
}

.innerLogoSite {
  text-align: center;
  width: 100%;
}

.innerHeaderComponent {
  position: sticky;
  top: 0;
  z-index: 50;
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerItemFirst {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 584px;
  margin-right: 10px;
  width: 100%;
}

.headerItemSecond {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 641px;
  width: 100%;
}

.wrapperFirstHeaderItem {
  display: flex;
  align-items: center;
  max-width: 308px;
  width: 100%;
}

.wrapperItemHeader {
  display: flex;
  align-items: center;
}

.wrapperItemHeader .userHeaderDropDown {
  overflow-y: visible;
  overflow-x: auto;
}

.headerLinkHelp {
  margin-right: 60px;
}

.innerDropDownHeader .recipesLink {
  font-size: 24px;
  line-height: 36px;
  color: #f7f5ad;
  margin-left: 40px;
  width: initial;
}

.innerDropDownHeader {
  max-width: 180px;
  width: 100%;
}

.innerDropDownHeader .dropdown-menu {
  margin-top: 0;
}

.recipesLink:hover {
  color: #f7f5ad;
}

.innerLinkGroups {
  width: 187px;
  padding: 0;
}

.innerLinkGroups:active {
  background-color: #ffffff;
}

.groupsItem,
.groupsItemSecond {
  display: block;
  font-size: 14px;
  line-height: 19px;
  padding: 23px;
  text-align: center;
  color: #37190c;
  font-family: CourierPrime-Regular, sans-serif;
  transition: all 0.5s;
  cursor: pointer;
  overflow: hidden;
}

.groupsItem:hover,
.groupsItemSecond:hover {
  color: #37190c;
  background-color: #f1f0f0;
}
.groupsItemSecond {
  font-size: 18px;
  line-height: 19px;
}

.innerUserHeaderInfo {
  display: flex;
  align-items: center;
}

.userAvatar {
  width: 52px;
  height: 52px;
  border: 2px solid #fec925;
  border-radius: 50%;
}

.userName {
  margin: 0 0 0 17px;
  max-width: 87px;
  min-width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.headerLinkHelp {
  font-size: 24px;
  line-height: 36px;
  color: #f7f5ad;
}
.headerLinkHelp:hover {
  color: #e1df9f;
}
.inviteBtn {
  background-color: transparent;
  color: #f7f5ad;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 14px 15px 28px;
  white-space: nowrap;
  border: 2px solid #fec925;
  border-radius: 13px;
}

.inviteBtn:hover {
  color: #f7f5ad;
}

.inviteBtn svg {
  margin-left: 10px;
}

.mobileHeader {
  display: none;
}

.btnBurger {
  width: 23px;
  cursor: pointer;
  transition: all 0.5s;
}

.spanBtnBurger {
  display: block;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  height: 3px;
  background: #f7f5ad;
  transition: all 0.5s;
  position: relative;
}

.spanBtnBurger + .spanBtnBurger {
  margin-top: 5px;
}

.active .spanBtnBurger:nth-child(1) {
  top: 8px;
  transform: rotate(45deg);
}

.active .spanBtnBurger:nth-child(2) {
  transform: scale(0);
}

.active .spanBtnBurger:nth-child(3) {
  bottom: 8px;
  transform: rotate(135deg);
}

.innerHeaderMenu {
  display: none;
}
.dropTheme {
}
.wrapperAllGroupsHeader {
  max-height: 195px;
  text-overflow: ellipsis;
}
.dropTheme .groupsItem {
  background-color: #f7f5ad;
}
.dropTheme .groupsItem:hover {
  background-color: #fec925;
}
@media (max-width: 991px) {
  .groupsItem,
  .groupsItemSecond {
    padding: 15px;
  }
  .headerContainer {
    padding: 0 23px;
  }
  .mobileHeader {
    display: block;
  }
  .innerMobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .headerInner {
    display: none;
  }
  .userAvatar {
    width: 50px;
    height: 50px;
    border: 2px solid #fec925;
  }
  .innerHeaderMenu {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 30;
    top: -100%;
    left: 0;
    background-color: #f7f5ad;
    transition: all 0.5s;
  }
  .navigateMenuItem:hover {
    background-color: #fec925;
    color: #37190c;
    transition: all 0.5s;
  }
  .openMenu {
    top: 90px;
  }
  .navigateMenuItem {
    font-family: 'Rubik-Bold';
    font-size: 24px;
    line-height: 43px;
    display: block;
    text-align: center;
    width: 100%;
    padding: 15px 0;
    color: #37190c;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    border-left: 1px solid #000000;
  }
  .navigateMenuItem:first-child {
    border: 1px solid #000000;
  }
  .headerLogo {
    max-width: 100px;
  }
}
