.innerNotification {
  max-width: 680px;
  width: 100%;
  margin: 106px auto 180px;
}

.titleNotification {
  font-family: 'Rubik-Bold';
  font-size: 48px;
  line-height: 43px;
  color: #37190c;
  margin-bottom: 46px;
  text-align: center;
}

.notificationCheckbox {
  display: flex;
  align-items: baseline;
  max-width: 682px;
  width: 100%;
  margin: 0 auto;
}

.checkboxNotificationText {
  font-family: 'CourierPrime-Regular';
  font-size: 24px;
  line-height: 36px;
  color: #37190c;
  margin-left: 20px;
}

.notificationCheckbox:first-child {
  margin-bottom: 20px;
}

.notificationCheckbox:not(:first-child) {
  margin: 10px 0;
}

.innerFooterSettings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 652px;
  margin: 0 auto 50px;
}

.successInviteFriends {
  font-family: 'CourierPrime-Regular';
  font-size: 14px;
  line-height: 19px;
  color: #2ad870;
  max-width: 309px;
  text-align: justify;
  margin: 0 20px 0;
}
.innerButtonInvite {
  max-width: 256px;
  width: 100%;
}

.inviteButtonAccount {
  display: block;
  background-color: #fec925;
  border-radius: 13px;
  cursor: pointer;
  color: #37190c;
  margin-top: 0;
  max-width: 256px;
}

.inviteButtonAccount:hover {
  background-color: #e1af24;
  color: #37190c;
  transition: all 0.5s;
}

.backLink {
  display: flex;
  align-items: center;
  font-family: 'CourierPrime-Regular';
  font-size: 24px;
  line-height: 24px;
  color: #37190c;
  text-decoration: underline;
}

.innerBackBtnManageFams {
  max-width: 920px;
  width: 100%;
  margin: 0 auto 30px;
}

.backLink:hover {
  color: #37190c;
  text-decoration: none;
}

.arrowBack {
  margin-right: 10px;
}

@media (max-width: 991px) {
  .successInviteFriends {
    text-align: center;
  }
  .innerNotification {
    margin: 35px auto 66px;
  }
  .titleNotification {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 25px;
  }
  .checkboxNotificationText {
    font-size: 20px;
    line-height: 29px;
  }
  .backLink {
    font-size: 18px;
  }
  .innerBackBtnManageFams {
    max-width: 700px;
  }
}
