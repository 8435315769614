.innerUsers .userProfilePhoto {
  -webkit-transform: translateZ(0);
  max-width: 136px;
  height: 136px;
  margin: 0 auto;
  display: block;
  width: 100%;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.25));
  border-radius: 42px;
  object-fit: cover;
  box-shadow: none;
}

.textProfileBlock {
  font-family: 'CourierPrime-Regular';
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

.innerUsers .joinedText {
  margin: 43px 0 50px;
}

@media (max-width: 991px) {
  .innerUsers .userProfilePhoto {
    max-width: 100px;
    height: 100px;
  }
  .textProfileBlock {
    font-size: 21px;
    line-height: 28px;
  }
  .innerUsers .joinedText {
    margin: 20px 0;
  }
}
