.innerAllPlans {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.wrapperPlant {
  background-color: #f7f5ad;
  padding: 20px 0;
  min-height: fit-content;
  width: calc(100% / 2);
  position: relative;
  border-radius: 5px;
  border: 2px solid #893f1d;
  overflow: hidden;
}

.titlePlan {
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 20px;
  color: #37190c;
}

.pricePlan {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Rubik-Regular';
  text-align: center;
  font-size: 16px;
  color: rgb(137, 63, 29);
  padding: 13px 0;
  background-color: #f8a61b;
  margin: 0 -2px;
}

.pricePlan > span {
  font-size: 36px;
  line-height: 38px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: #893f1d;
}

.listOpportunitiesPlan {
  list-style: none;
  margin: 20px 15px 60px;
}

.itemOpportunities {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 13px 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-family: 'Rubik-Regular';
  font-size: 22px;
  text-align: left;
  line-height: 24px;
}

.itemOpportunities:last-child {
  border-bottom: none;
}

.tickStyle {
  margin: -5px 10px 0 0;
  min-width: 16px;
}

.subscribePlan {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  font-family: 'Rubik-Regular';
  font-size: 18px;
  line-height: 20px;
  color: #893f1d;
  font-weight: 700;
  background-color: #f8a61b;
  border-radius: 6px;
  transition: background-color 0.5s;
  padding: 15px 0;
  border: none;
}

.subscribePlan:hover {
  background-color: #d98e0d;
}

.discountPricing {
  position: absolute;
  right: -26px;
  top: 16px;
  transform: rotate(40deg);
  font-family: 'Rubik-Regular';
  width: 130px;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  background-color: #f8463f;
  text-transform: uppercase;
  padding: 5px 0;
  color: #ffffff;
}

@media (max-width: 700px) {
  .innerAllPlans {
    flex-direction: column;
    align-items: center;
  }
  .wrapperPlant {
    max-width: 500px;
    width: 100%;
  }
  .titlePlan {
    font-size: 24px;
    line-height: 26px;
  }
  .pricePlan > span {
    font-size: 28px;
    line-height: 28px;
  }
}
