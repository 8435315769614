.buttonWrapper {
  border: none;
  width: 100%;
  height: 52px;
  background-color: #57ad91;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  transition: all 0.3s ease-out;
}
.buttonWrapper:hover {
  background-color: #4b987f;
  color: white;
  transition: all 0.3s ease-out;
}
.buttonText {
  font-family: Rubik-Medium, sans-serif;
  font-size: 18px;
  line-height: 19px;
}
.buttonGetStarted {
  max-width: 302px;
  margin-left: auto;
  margin-right: auto;
}
.form-control:focus {
  border-color: rgb(178, 178, 178);
  box-shadow: none;
}
