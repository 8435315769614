.innerDraftRecipe {
  display: flex;
  justify-content: center;
}

.withoutDraftRecipe {
  text-align: center;
  font-size: 26px;
  grid-column: auto / span 12;
}

.draftsContainer .innerHeaderRecipes {
  margin-top: 0;
}
