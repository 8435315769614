.recipeText {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 60px;
  color: #37190c;
  font-family: CourierPrime-Regular, sans-serif;
}
.recipeTextSmall {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 60px;
  color: #37190c;
  font-family: CourierPrime-Regular, sans-serif;
  zoom: 1.5;
}
.recipeTitle,
.recipeTitleSecond {
  color: #37190c;
  font-size: 48px;
  font-family: Rubik-Bold, sans-serif;
}
.recipeTitleSecond {
  line-height: 43.5px;
  margin-bottom: 18px;
}
@media (max-width: 991px) {
  .recipeTitle,
  .recipeTitleSecond {
    color: #37190c;
    font-size: 24px;
    font-family: Rubik-Bold, sans-serif;
  }
  .recipeTextSmall {
    margin-bottom: 16px;
    zoom: 1;
  }
}
