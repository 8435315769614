.errorPage {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  justify-content: center;
}

.innerErrorModal {
  font-family: 'CourierPrime-Regular';
  font-size: 100px;
  line-height: 100px;
  text-align: center;
}

.textPageError {
  font-family: 'Regular-Bold';
  font-size: 22px;
  text-align: center;
}
