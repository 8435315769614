.wrapperGroupUsers {
  max-width: 652px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.innerItemUser {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerItemUser:not(:first-child) {
  margin-top: 37px;
}

.wrapperBtnClose {
  margin-right: 20px;
  cursor: pointer;
  transition: transform 0.5s;
}

.hideRemoveBtn {
  min-width: 40px;
  min-height: 40px;
  margin-right: 20px;
}

.wrapperBtnClose:hover {
  transform: scale(1.1);
}

.userNameGroup {
  font-family: 'CourierPrime-Regular';
  font-size: 24px;
  line-height: 36px;
  margin: 0 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 202px;
}

.makeItemBtn {
  font-family: 'CourierPrime-Regular';
  font-size: 24px;
  line-height: 36px;
  text-decoration-line: underline;
  color: #893f1d;
  cursor: pointer;
}

.groupSettingsBack {
  margin: 160px 0 20px;
}

.groupSettingsBack a {
  color: #37190c;
}

.btnMakeAdmin {
  white-space: nowrap;
  margin: 0;
  width: 173px;
}

.userNameInfo {
  display: flex;
  align-items: center;
  max-width: calc(100% - 80px);
}

.innerUsersGroup {
  height: 540px;
  padding: 10px 0;
  overflow-y: scroll;
}

.withoutUsers {
  text-align: center;
  font-size: 26px;
}

.fakeMakeAdmin {
  width: 144px;
}

@media (max-width: 991px) {
  .btnMakeAdmin {
    width: initial;
  }
  .makeItemBtn {
    font-size: 15px;
    line-height: 28px;
    margin: 0 30px;
  }
  .hideRemoveBtn {
    margin-left: 20px;
  }
  .wrapperBtnClose {
    margin-left: 20px;
  }
  .innerUsersGroup {
    height: 400px;
  }
  .fakeMakeAdmin {
    display: none;
  }
  .innerItemUser {
    max-width: 512px;
    margin: 0 auto;
  }
  .userNameInfo {
    display: block;
  }
  .userNameGroup {
    margin-left: 15px;
    margin-right: 0;
    font-size: 20px;
    line-height: 30px;
    width: 168px;
  }
  .userNameInfo .btnMakeAdmin {
    margin-left: 15px;
  }
  .groupSettingsBack {
    margin-top: 40px;
  }
}
