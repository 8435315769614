.wrapper {
  min-height: 100vh;
  display: flex;
  background-color: #57ad91;
  padding: 16px;
  align-items: center;
  justify-content: center;
  text-align: left;
  position: relative;
  background-image: url('../../assets/backText.png');
  background-position: -2% 147px;
  background-repeat: no-repeat;
  background-size: 2900px;
}
/*header*/
.headerSignUp {
  max-width: 1600px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 147px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.innerLogoSiteRegistration {
  width: 100%;
  max-width: 125px;
}
.siteLogo {
  max-width: 120px;
  width: 100%;
}
.headerSignUp div {
  cursor: pointer;
}
.headerText {
  font-family: CourierPrime-Regular, sans-serif;
  color: #f7f5ad;
}

.headerText:hover {
  color: #f7f5ad;
}
/*header*/
/*modalStyle*/
.modalSignUp {
  max-width: 550px;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 147px 16px;
  padding: 20px 60px 20px;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 10px 10px 0 #f7f5ad;
}
.modalTitle {
  font-family: Rubik-Bold, sans-serif;
  text-align: center;
  font-size: 24px;
  line-height: 43.5px;
  color: #893f1d;
}
.requiredText {
  text-align: center;
  color: rgb(134, 133, 133);
}
.modalSubTitle {
  max-width: 320px;
  font-family: 'CourierPrime-Regular', sans-serif;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
}
.subTitleSignUp {
  font-family: 'CourierPrime-BoldItalic', sans-serif;
  color: #fec925;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.innerRecaptcha {
  max-width: 300px;
  margin: 16px auto 0;
}

.formSignUp .buttonText:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.subTitleSignUp:hover {
  color: #fec925;
}
.modalForget {
  width: 100%;
  font-family: CourierPrime-Regular, sans-serif;
  margin-top: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  color: #37190c;
}
.modalForget:hover {
  color: #37190c;
}
.linkText {
  margin-top: 8px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  color: #37190c;
}
.linkText:hover {
  color: #37190c;
}
.formFam {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  width: 100%;
}
.innerButtonInviteFriends {
  display: flex;
}
.innerList {
  width: 100%;
}
.submitAdd {
  display: flex;
  justify-content: center;
  font-size: 36px;
  line-height: 43.5px;
  margin-left: 11px;
  border: 3px solid #e0e0e0;
  background-color: #ffffff;
  color: #e0e0e0;
  border-radius: 5px;
  width: 91px;
  height: 52px;
  margin-top: 16px;
  overflow-y: hidden;
}

.dropDownArray {
  padding-right: 55px;
}
.formSignUp {
  width: 100%;
}
.skipButton {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  width: 172px;
  height: 29px;
  border-radius: 5px;
  border: 3px solid #919090;
  color: #919090;
  font-size: 14px;
  cursor: pointer;
}
.skipButton:hover {
  color: #e0e0e0;
}
.modalCheck {
  position: absolute;
  left: 0;
}
.modalCheckText {
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 12px;
  line-height: 12.5px;
}
.modalCheckText a {
  color: #37190c;
}
.modalCheckWrap {
  width: 100%;
  padding-left: 35px;
  font-size: 12px;
  line-height: 12.5px;
  margin-top: 16px;
  position: relative;
}
.modalSignUp .inputContent input {
  padding-left: 50px;
}
/*modalStyle*/

@media (max-width: 991px) {
  .modalSignUp {
    padding: 20px;
    margin: 117px 0;
  }
}
@media (max-width: 375px) {
  .headerSignUp {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .innerRecaptcha {
    transform: scale(0.8);
    transform-origin: 0 center;
    max-width: 245px;
  }
}
