.customDropdown {
  max-width: 233px;
  width: 100%;
}

.toggleDrop {
  color: #f7f5ad;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  justify-content: right;
  align-items: center;
  white-space: nowrap;
}

.toggleDrop:hover {
  color: #f7f5ad;
}

.downArrow {
  margin-left: 5px;
  margin-top: 5px;
}

.customDropdownMenu {
  overflow: auto;
  /*z-index: 30;*/
  padding: 0;
}

.customDropdownMenu::-webkit-scrollbar {
  background-color: #f7f5ad;
  width: 7px;
  height: 0;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.customDropdownMenu::-webkit-scrollbar-thumb {
  background: #88875d;
  border-radius: 5px;
}

.dropdown {
  width: 100%;
}
.dropdown-menu {
  margin-top: -10px;
  border: none;
  border-radius: 9px;
  box-shadow: 0 4px 19px 0 #00000040;
}
