.innerFormManageRecipe {
  position: relative;
}

.containerRecipeMain {
  position: relative;
  max-width: 1350px;
  margin: 0 auto;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.innerFormManageRecipe .innerLoadingSpinner {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
.addRecipeWrap {
  display: flex;
  flex: 1;
  color: #37190c;
  justify-content: space-between;
  align-items: flex-start;
}
.stepRecipeWrap {
  display: flex;
  margin: 100px 16px 100px 0;
  max-width: 376px;
  min-width: 280px;
  width: 100%;
}
.recipeContentWrap {
  display: flex;
  flex: 1;
  height: 100%;
  max-width: 773px;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.recipeContent {
  display: flex;
  flex: 1;
  margin: 80px 0 78px 32px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  zoom: 0.68;
}
.innerRecipeName {
  width: 100%;
}
.inputRecipeTitle {
  height: 92px;
  font-size: 48px;
  color: #37190c;
  font-family: Rubik-Bold, sans-serif;
}
.inputRecipeTitle.form-control:focus {
  color: #37190c;
}
.inputRecipeNumber {
  font-size: 24px;
  font-family: CourierPrime-Regular, sans-serif;
}
.numberInputCustom::-webkit-outer-spin-button,
.numberInputCustom::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numberInputCustom {
  -moz-appearance: textfield;
}
.dropSecondStep {
  width: 100%;
}
.recipeCategoriesWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  zoom: 1.4;
}
.categoriesTitle {
  font-family: CourierPrime-Regular, sans-serif;
  text-align: left;
  margin-top: 11px;
  font-size: 24px;
  line-height: 36px;
  color: #37190c;
}
.recipeCategoriesItem {
  width: 210px;
  height: 35px;
  display: flex;
  align-items: center;
  margin: 0 8px 0 19px;
}
.recipeCategoriesWrap .biggerCheckBox {
  width: 30px;
  height: 30px;
}
.recipeCategoriesText {
  margin-left: 19px;
  text-align: left;
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #37190c;
  margin-top: 5px;
}
.recipeGroupDrop {
  width: 100%;
  margin-left: 0;
  margin-top: 16px;
  font-size: 18px;
  line-height: 19px;
}

.recipeGroupTags {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.listForm {
  width: 100% !important;
}

.dropDownPaddingRight {
  padding-right: 55px;
}
.recipeGroupTag {
  width: 194px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-right: 18px;
  border-radius: 5px;
  background-color: #e0e0e0;
  font-family: Rubik-Regular, sans-serif;
  font-size: 14px;
  position: relative;
}
.recipeTagClose {
  position: absolute;
  cursor: pointer;
  top: -6px;
  right: -7px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #37190c;
  color: #f7f5ad;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Rubik-Regular, sans-serif;
  font-size: 8px;
}
.recipeUploadWrap {
  position: relative;
  width: 100%;
  height: 224px;
  background-color: #f1f0f0;
  border-radius: 5px;
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
}
.recipeButtonUpload,
.recipeButtonAddIngredient {
  background-color: #fec925;
  width: 437px;
  height: 52px;
  margin-top: 0;
  font-family: Rubik-Medium, sans-serif;
  color: #37190c;
  box-shadow: 0 4px 4px 0 #00000040;
}
.recipeButtonUpload:hover,
.recipeButtonAddIngredient:hover {
  background-color: #e1ad24;
  color: #37190c;
  transition: all 0.5s;
}
.recipeButtonAddIngredient {
  margin-top: 25px;
  margin-bottom: 100px;
}
.navigationButtonWrap {
  display: flex;
  width: 100%;
  margin: 16px 0 120px;
  justify-content: space-between;
  align-items: center;
}
.navigationLinkWrap,
.navigationSingleWrap {
  display: flex;
  flex: 1;
  width: 100%;
  margin-top: 32px;
  align-items: center;
  justify-content: space-between;
}
.navigationSingleWrap .recipeSave {
  justify-content: flex-start;
}
.nextButton {
  flex: 1;
  margin-top: 0;
  min-height: 89px;
  max-width: 437px;
}
.innerAllSteps {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  width: 100%;
}
.innerHeaderSteps {
  display: flex;
  align-items: center;
}
.recipeStepWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.buttonNextStep {
  border: none;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
}
.recipeStepCircle {
  width: 59px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #e0e0e0;
  color: #828282;
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 24px;
  line-height: 36px;
  cursor: pointer;
  border: 3px solid #bdbdbd;
}

.activeCircle {
  background-color: #57ad91;
  color: #f7f5ad;
  border: 3px solid #57ad91;
}
.focusCircle {
  background-color: transparent;
  color: #57ad91;
  border: 3px solid #57ad91;
}

.recipeStep {
  height: 151px;
  width: 3px;
  background-color: #bdbdbd;
}
.recipeActiveStep {
  background-color: #57ad91;
}

.recipeStepItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.recipeStepText {
  position: absolute;
  left: 78px;
  font-family: CourierPrime-Regular, sans-serif;
  color: #828282;
  font-size: 24px;
  line-height: 36px;
  width: 280px;
  text-align: left;
}
.navigationSave {
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
}

.recipeSave {
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 18px;
  line-height: 25px;
  font-family: CourierPrime-Regular, sans-serif;
  text-decoration: underline;
  color: #37190c;
  background-color: transparent;
  border: none;
}
.recipeSave:hover,
.recipeBack:hover {
  opacity: 0.7;
  text-decoration: none;
  color: #37190c;
}
.recipeBack {
  font-size: 18px;
  line-height: 25px;
  font-family: CourierPrime-Regular, sans-serif;
  color: #37190c;
  text-decoration: underline;
}

.textAreaDescription::placeholder {
  color: #e0e0e0;
}

.textAreaDescription,
.textAreaSteps {
  max-height: 220px;
  height: 220px;
  font-size: 24px;
  line-height: 36px;
  padding: 15px 12px;
  color: #37190c;
  font-family: CourierPrime-Regular, sans-serif;
}
.errorDescriptionLength {
  font-size: 24px;
  line-height: 36px;
  margin: 0 5px 0 auto;
  color: red;
}

.textAreaSteps {
  margin-left: 20px;
  margin-right: 17px;
}
.deleteIcon {
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: -12px;
}
.stepWrap:first-child .deleteStepsArray {
  visibility: hidden;
}
.desView {
  display: block;
}
.mobView {
  display: none;
}
.ingredientsCount {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 991px) {
  .recipeCategoriesWrap {
    zoom: 1;
  }
  .innerAllSteps {
    max-width: 500px;
    padding: 0 10px;
    width: 100%;
  }
  .navigationSingleWrap {
    justify-content: center;
  }
  .recipeSave {
    flex: none;
  }
  .recipeContentWrap {
    align-items: initial;
  }

  .recipeContentWrap {
    max-width: 100%;
  }

  .mobView {
    display: block;
  }
  .desView {
    display: none;
  }
  .addRecipeWrap {
    flex-direction: column-reverse;
    align-items: center;
  }

  .stepRecipeWrap {
    margin: 16px 0;
    justify-content: center;
    max-width: 100%;
    min-width: 100%;
  }
  .recipeStepText {
    display: none;
  }
  .recipeStepWrap {
    flex-direction: row;
  }
  .recipeStep {
    width: 54px;
    height: 2px;
  }
  .recipeStepCircle {
    width: 40px;
    height: 40px;
  }
  .recipeContent {
    width: 100%;
    margin: 24px 0 0;
    zoom: 1;
  }
  .inputRecipeTitle {
    height: 58px;
    font-size: 24px;
  }
  .categoriesTitle {
    font-size: 14px;
    line-height: 20px;
  }
  .textAreaDescription,
  .textAreaSteps {
    max-height: 199px;
    height: 199px;
    font-size: 18px;
    line-height: 24px;
    padding: 17px 15px;
  }
  .textAreaSteps {
    margin-right: 0;
    margin-left: 10px;
  }
  .recipeGroupTags {
    flex-direction: column;
  }
  .recipeGroupTag {
    margin-bottom: 14px;
    width: 100%;
  }
  .recipeButtonUpload,
  .recipeButtonAddIngredient {
    width: 100%;
    height: 63px;
  }
  .navigationButtonWrap {
    flex-direction: column-reverse;

    padding: 0 8px;
  }
  .nextButton {
    min-height: 77px;
    max-width: 100%;
  }
  .navigationSave {
    margin-top: 11px;
  }
  .navigationLinkWrap,
  .navigationSingleWrap {
    width: 100%;
    margin-right: 0;
    margin-top: 35px;
  }

  .dropDownPaddingRight {
    font-size: 18px;
    line-height: 24px;
  }
  .recipeButtonWrap {
    width: 100%;
    padding: 0 8px;
  }
  .ingredientsWrap {
    max-width: 356px;
    width: 100%;
    flex-direction: column;
  }
  .ingredientsCount {
    max-width: 356px;
    width: 100%;
    margin-bottom: 11px;
    justify-content: space-between;
  }
  .globalInnerIngredients {
    flex-direction: column;
  }
  .textAreaSteps {
    font-size: 18px;
    line-height: 19px;
    padding: 10px 13px;
  }
  .recipeUploadWrap {
    padding: 0 30px;
  }
  .errorDescriptionLength {
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
  }
}
@media (max-width: 375px) {
  .navigationButtonWrap {
    padding: 0;
  }
}
