.titleManageFams {
  margin: 102px 0 133px;
}

.innerInfoFams {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1210px;
  width: 100%;
  margin: 0 auto 100px;
}

.itemFams {
  max-width: 732px;
  width: 100%;
  margin-right: 30px;
}

.innerAllFams {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.innerAllFams:not(:first-child) {
  margin-bottom: 11px;
}

.titleItemFams {
  white-space: nowrap;
}

.titleItemFamsYour {
  text-align: center;
}

.nameYourFam {
  font-family: 'CourierPrime-Regular';
  font-size: 24px;
  line-height: 36px;
  color: #37190c;
  text-align: center;
  transition: all 0.5s;
}

.nameYourFam:hover {
  color: #37190c;
  text-decoration: underline;
}

.groupsName {
  width: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}

.btnLeaveFams {
  color: #db5a5a;
  text-align: center;
  cursor: pointer;
}

.createBtnFams {
  max-width: 304px;
  margin-left: auto;
}

.linkManage {
  margin: 0 5px;
  text-align: center;
}

.linkManage:hover {
  color: #893f1d;
}

.innerLeaveFromGroup {
  padding-bottom: 20px;
}

.leaveGroupModalbutton {
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .groupsName {
    width: 180px;
  }
  .createBtnFams {
    max-width: initial;
  }
  .titleManageFams {
    font-size: 24px;
    line-height: 43px;
    margin: 30px auto 25px;
  }
  .innerInfoFams {
    flex-direction: column;
    align-items: center;
  }
  .itemFams {
    margin-right: 0;
  }
  .nameYourFam {
    font-size: 15px;
    line-height: 28px;
  }
  .innerAllFams {
    justify-content: space-between;
    max-width: 345px;
    margin: 0 auto;
  }
  .innerFomCreateGroup {
    max-width: 400px;
    width: 100%;
  }
}
