.innerInputAddon {
  display: flex;
  max-width: 437px;
  margin: 20px auto;
}

.innerInputAddon + .innerInputAddon {
  margin-top: 20px;
}

.innerInputAddon:last-child {
  margin-bottom: 20px;
}

.innerInputAddon .buttonAddons {
  margin: 0 0 0 10px;
}

.innerInputAddon .formSignUpGroup {
  width: 100%;
}

.addonLinkBack {
  margin-top: 20px;
}

.statusPending {
  display: flex;
  align-items: center;
  background-color: rgb(222 178 57);
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 10px;
}

@media (max-width: 991px) {
  .addonsButtonForm {
    line-height: 51px;
  }
}
