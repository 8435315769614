.modalTrialContent {
  margin: 16px;
  padding: 52px 30px 57px;
  border-radius: 14px;
  align-items: center;
}
.modalTrialDialog {
  display: flex;
  max-width: 738px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 14px;
  text-align: center;
}
.modalTrialText {
  color: #000;
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 24px;
  line-height: 36px;
}
.modalExit {
  color: #000;
  cursor: pointer;
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 14px;
  line-height: 40px;
  margin-top: 10px;
}
.innerDropdownTypeSubscr {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}
