.innerAccount {
  margin-top: 80px;
  padding: 0 16px;
}

.innerAccountInfoUser {
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
}

.accountSettingsTitle {
  font-family: 'Rubik-Bold';
  font-size: 48px;
  line-height: 43px;
  text-align: center;
  color: #37190c;
}

.wrapperPhotoUser {
  display: block;
  position: relative;
  width: 110px;
  height: 110px;
  margin: 84px auto 26px;
}

.accountPhoto {
  -webkit-transform: translateZ(0);
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.25));
  border-radius: 42px 42px 0 42px;
  -webkit-border-radius: 42px 42px 0 42px;
  -moz-border-radius: 42px 42px 0 42px;
  -khtml-border-radius: 42px 42px 0 42px;
  width: 110px;
  height: 110px;
  object-fit: cover;
}

.innerChangePencil {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #37190c;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 42px 42px 0 42px;
  width: 32px;
  height: 32px;
  z-index: 9;
}

.changePhotoInput {
  position: absolute;
  max-width: 100%;
  z-index: 10;
  opacity: 0;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  cursor: pointer;
}

.pencilPhoto {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.changePhotoInput[type='file']::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.formChaneName {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.changeName {
  font-family: 'Rubik-Bold';
  padding: 10px 10px 8px;
  background: #ffffff;
  border: 3px solid #e0e0e0;
  border-radius: 5px;
  font-size: 24px;
  line-height: 36px;
  color: #37190c;
  width: 100%;
}

.changeNameText {
  background-color: transparent;
  border-color: transparent;
}

.changeName:focus {
  outline: none;
}

.btnChangeName {
  background-color: #57ad91;
  color: #ffffff;
  cursor: pointer;
  border: none;
  margin-left: 18px;
  height: 60px;
  font-family: 'Rubik-Bold';
  padding: 21px 30px;
  border-radius: 7px;
}

.changeBtnNotification {
  margin: 0 0 20px;
  max-width: 300px;
  width: 100%;
}

@media (max-width: 991px) {
  .btnChangeName {
    padding: 13px 30px;
    height: 55px;
  }
  .innerAccount {
    padding-bottom: 66px;
  }
  .innerAccount {
    margin-top: 50px;
  }
  .accountSettingsTitle {
    font-size: 24px;
    line-height: 43px;
  }
  .wrapperPhotoUser {
    margin: 30px auto 16px;
  }
  .changeName {
    font-size: 21px;
    line-height: 30px;
  }
}

@media (max-width: 573px) {
  .formChaneName {
    max-width: 300px;
    margin: 0 auto;
  }
}
