.containRecipe {
  margin-top: 67px !important;
}

.innerListRecipes {
  width: 100%;
  max-width: 1189px;
  margin-bottom: 30px;
  padding: 0 16px;
}

.innerTitleDraft {
  margin-left: 150px;
}

.innerDraftRecipePadding {
  padding: 0 16px;
}

.innerHeaderRecipes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.addNewRecipes {
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 43px;
  text-align: center;
  max-width: 256px;
  height: 74px;
  padding: 15px 10px 11px;
  color: #37190c;
  background: #fec925;
  white-space: nowrap;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  margin-top: 0;
  margin-left: 10px;
}
.addNewRecipes:hover {
  background-color: #e1af24;
  color: #37190c;
  transition: all 0.5s;
}

.titleList {
  font-family: 'Rubik-Bold';
  position: relative;
  font-size: 48px;
  line-height: 50px;
  width: 100%;
  text-align: center;
}

.countRecipes {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  position: absolute;
  font-family: 'Rubik-Regular';
  font-size: 16px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 300px;
  width: 100%;
  bottom: -25px;
  line-height: 24px;
}

.linkToManage {
  color: #57ad91;
  text-decoration: underline;
}

.linkToManage:hover {
  color: #57ad91;
}

.numberRecipes {
  min-width: 103px;
}

.titleRecipeProfile {
  line-height: 74px;
}

.innerListRecipesAll {
  grid-gap: 48px;
  display: grid;
  grid-template-columns: repeat(12, [col] 1fr);
}

.innerRecipe {
  position: relative;
  width: 100%;
  height: 252px;
  font-size: 24px;
  line-height: 26px;
  color: #f7f5ad;
  max-width: 365px;
  min-width: 130px;
  border-radius: 6px;
  grid-column: auto / span 4;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
  transition: all 0.3s ease-out;
}
.innerRecipe:hover {
  color: #f7f5ad;
  opacity: 0.9;
  transition: all 0.3s ease-out;
}
.recipeImage {
  z-index: -1000;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.innerRecipe:nth-child(6n + 6) {
  max-width: 505px;
  grid-column: auto / span 5;
}

.innerRecipe:nth-child(6n + 3) {
  grid-column: auto / span 3;
}

.innerRecipe:nth-child(3n + 2) {
  max-width: 505px;
  grid-column: auto / span 5;
}

.innerRecipe:nth-child(6n + 5) {
  max-width: 365px;
  grid-column: auto / span 4;
}

.innerRecipe:nth-child(6n + 4) {
  grid-column: auto / span 3;
}

.countRecipe {
  font-family: Rubik-Bold, sans-serif;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.nameRecipe {
  font-family: Rubik-Bold, sans-serif;
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 10;
  max-width: 95%;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
}

.darkWrapper {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.24);
  border-radius: 6px;
}
.listAvatar {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  min-width: 60px;
  width: 60px;
  height: 60px;
}

@media (max-width: 1100px) {
  .innerListRecipesAll {
    grid-gap: 24px;
  }
}

@media (max-width: 991px) {
  .innerTitleDraft {
    margin-left: 0;
  }
  .innerHeaderRecipes {
    justify-content: center;
    margin: 53px 0 20px;
  }
  .titleList {
    font-size: 24px;
    width: 100%;
    line-height: 43px;
  }
  .recipeBtnAdd {
    display: none;
  }
  .innerListRecipesAll {
    grid-gap: 25px;
  }
  .innerListRecipesAll .innerRecipe {
    grid-column: auto / span 6;
    max-width: initial;
  }
  .containRecipe {
    margin-top: 30px !important;
  }
  .countRecipes {
    bottom: -15px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .innerListRecipesAll {
    grid-gap: 20px;
  }
  .innerListRecipesAll .innerRecipe {
    grid-column: auto / span 12;
  }
}
