.innerModalRemoveUser {
  padding: 73px 30px 65px;
  position: relative;
}

.textModalRemoveFirst {
  font-family: 'CourierPrime-Regular';
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  text-align: center;
}

.removeUserButton {
  display: block;
  font-family: 'Rubik-Medium';
  font-size: 24px;
  line-height: 36px;
  max-width: 375px;
  width: 100%;
  padding: 25px 0;
  background: #ef2127;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 27px auto;
  color: #ffffff;
  border: none;
}

.textModalRemoveSecond {
  font-family: 'CourierPrime-Regular';
  font-style: italic;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

.secondTextMargin {
  margin: 50px 0 25px;
}

.modalButtonSuccess {
  background: #57ad91;
}
.viewRecipeBtn {
  background-color: #678178;
}
.modalButtonSuccess:hover {
  background-color: #4b987f;
}
@media (max-width: 991px) {
  .innerModalRemoveUser {
    padding: 73px 30px 30px;
  }
  .textModalRemoveFirst {
    font-size: 20px;
    line-height: 30px;
  }

  .removeUserButton {
    padding: 13px 0;
    font-size: 20px;
    line-height: 30px;
  }
  .textModalRemoveSecond {
    font-size: 20px;
    line-height: 30px;
  }
}
