.innerLoginAdmin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #57ad91;
}
.contentAdminPanel {
  max-width: 1774px;
  margin: 54px auto;
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: row;
  flex: 1;
}
.tableWrap {
  display: flex;
  flex: 1;
  justify-content: center;
}
.tableSmallWrap {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.navigationPanel {
  min-width: 360px;
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
}
.navigationContent:after {
  content: '';
  width: 6px;
  border-radius: 5px;
  height: 100%;
  background-color: #37190c;
  position: absolute;
  right: 0;
}
.navigationContent a:first-child {
  margin-top: 10px;
}
.test {
  background-color: #1c00be;
}
.navigationItem {
  display: flex;
  width: 100%;

  align-items: center;
  text-align: left;
  cursor: pointer;
  padding-left: 80px;
  height: 89px;
}
.navigationItemTitle {
  font-family: Rubik-Bold, sans-serif;
  font-size: 24px;

  line-height: 43.5px;
  color: #37190c;
}
.navigationItemArrow {
  position: absolute;
  right: 100px;
}
.arrowLogout {
  position: relative;
  right: initial;
  transform: rotate(180deg);
}
.navigationContent {
  display: flex;
  flex: 1;
  margin-top: 40px;
  flex-direction: column;
  align-items: flex-start;
}
.navigationItem:hover {
  background-color: #e0e0e0;
}
.tableStyle {
  max-width: 1200px;
  width: 100%;
  margin: 0 16px;
}
.tableStyleGroups {
  margin: 0;
  max-width: 700px;
  width: 100%;
}
.changeRecipeStyle {
  width: 100%;
  height: 232px;
  display: flex;
  position: relative;
  justify-content: space-between;
  background-size: cover;
  align-items: flex-end;
  padding: 10px 10px 12px 16px;
  border-radius: 5px;
}
.adminText {
  font-family: 'CourierPrime-Bold', sans-serif;
  font-size: 18px;
  line-height: 19px;
}
.recipeAdminWrap {
  max-width: 750px;
  width: 100%;
}
.tableStyleRecipes {
  margin: 0;
  width: 500px;
}
/*Search*/
.searchTitle {
  text-align: left;
  font-family: 'CourierPrime-Bold', sans-serif;
  color: #37190c;
  line-height: 48px;
  font-size: 24px;
}

.tableTitle {
  font-size: 24px;
  line-height: 50px;
  font-family: CourierPrime-Bold, sans-serif;
}
.buttonTableTitle {
  font-family: Rubik-Bold, sans-serif;
  color: #f7f5ad;
  padding: 10px;
  font-size: 14px;
  line-height: 19px;
}
.buttonTable.buttonTable {
  margin-top: 0;
  margin-left: 18px;
  max-width: 231px;
}

/*thead*/
.table {
  margin-top: 16px;
  border-collapse: inherit;
}
.fixedTable {
  table-layout: fixed;
}
.table > :not(caption) > * > * {
  border-bottom-width: 0;
}

thead {
  position: relative;
}
thead:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 8px;
  bottom: 0;
  border-radius: 5px;
  background-color: #37190c;
}

.tableElement {
  max-width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
}

.innerAdminAllSteps .ingredientsCount {
  justify-content: center;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: none;
}

.adminAdWrap {
  display: flex;
  flex: 1;
  border-color: #37190c;
  justify-content: space-around;
}
.adminAsBlock {
  display: flex;
  margin: 0 38px;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.adminAdInputWrap {
  width: 100%;
  height: 75px;
}
.adminAdInput {
  height: 75px;
  max-width: 675px;

  width: 100%;
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}
.adFlow {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 574px;
  align-items: center;
  justify-content: center;
}
.adFlow .adDesktop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 400px;
  background-color: #f1f0f0;
  border-radius: 5px;
  border: 1px solid #893f1d;
}
.adMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px !important;
  height: 200px;
  background-color: #f1f0f0;
  border-radius: 5px;
  border: 1px solid #893f1d;
}
.successSaveAd {
  position: absolute;
  bottom: 0;
}
.buttonUploadAd.buttonUploadAd {
  background-color: #fec925;
  width: 214px;
  color: #37190c;
}
.buttonUploadAd:hover {
  background-color: #fec925;
  color: #37190c;
  transition: all 0.5s;
}
.saveAdButton {
  width: 214px;
  color: #f7f5ad;
}

.adminAsBlock .removeBtnAdvertising {
  background-color: red;
}

.adminAsBlock .removeBtnAdvertising:hover {
  background-color: #d70202;
}

.pagination {
  justify-content: center;
}
.page-item.disabled .page-link {
  background-color: transparent;
}
.page-link {
  width: 36px;
  height: 36px;
  font-family: CourierPrime-Regular, sans-serif;
  border: none;
  color: #37190c;
  background-color: transparent;
}
.page-link:hover {
  color: #37190c;
  border-radius: 20px;
}
.page-item.active .page-link {
  color: #fff;
  background-color: #37190c;
  border-color: #37190c;
  border-radius: 20px;
}

.adminUserContent {
  display: flex;
  flex: 1;
  padding: 0 16px;
  max-width: 1000px;
  margin: 70px auto;
  flex-direction: column;
}
.adminUserWrap,
.adminUserWrapButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 32px;
  flex-direction: row;
}
.adminUserWrapButtons {
  justify-content: flex-start;
}

.adminUserNameWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.adminUserName {
  margin-left: 20px;
}

.inputTable,
.inputTableRecipe {
  font-family: 'CourierPrime-Bold', sans-serif;
  padding: 10px 10px 8px;
  background: #ffffff;
  border: 3px solid #e0e0e0;
  border-radius: 5px;
  font-size: 36px;
  line-height: 36px;
  color: #37190c;
  max-width: 357px;
}

.nameRecipeAdmin {
  max-width: initial;
}
.inputTableRecipe {
  width: 100%;
  font-family: 'CourierPrime-Regular', sans-serif;
  font-size: 24px;
  line-height: 24px;
  max-width: 100%;
  height: 172px;
}

.inputTable:focus {
  outline: none;
}

.adminUserTitle {
  font-family: CourierPrime-Bold, sans-serif;
  font-size: 36px;
}
.innerStepsAdmin {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.adminUserText {
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 14px;
  line-height: 19px;
}
.adminUserDate {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: CourierPrime-Bold, sans-serif;
  font-size: 24px;
}
.adminUserAvatar {
  width: 97px;
  height: 97px;
  border-radius: 50px;
}
.photoUserChange {
  max-width: 100%;
  border-radius: 50%;
}
.adminUserResetButton.adminUserResetButton {
  max-width: 183px;
  width: 100%;
  padding: 0 16px;
  background-color: #fec925;
  text-align: center;
  font-family: Rubik-Regular, sans-serif;
  color: #37190c;
}
.adminUserResetButton.adminUserResetButton:hover {
  background-color: #e1b81b;
  color: #37190c;
  transition: all 0.5s;
}
.adminUserButtonText {
  font-size: 14px;
  line-height: 19px;
}
.adminCheckWrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.adminCheckWrap p {
  padding-left: 20px;
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 24px;
  line-height: 36px;
}

.adminCheckBox .form-check-input[type='checkbox'] {
  width: 30px;
  height: 30px;
}
.adminUserPauseButton {
  margin-top: 0;
  width: 183px;
  font-family: Rubik-Regular, sans-serif;
  background-color: #37190c;
}
.adminUserPauseButton:hover {
  background-color: #37190c;
}
.adminUserWrapButtons .adminUserPauseButton {
  margin: 16px 0 0 16px;
  max-width: 183px;
}
.adminUserDeleteButton.adminUserDeleteButton {
  margin-left: 16px;
  max-width: 183px;
  font-family: Rubik-Regular, sans-serif;
  background-color: #eb5757;
  color: #37190c;
}
.adminUserDeleteButton.adminUserDeleteButton:hover {
  background-color: #e15858;
}
.tableHeader {
  white-space: nowrap;
}
.innerOwnerName {
  display: block;
  max-width: 100px;
  overflow: hidden;
}
.adminTrLink {
  text-decoration: underline;
  color: #37190c;
}
.adminTrLink:hover {
  text-decoration: none;
  color: #37190c;
}
td,
th {
  position: relative;
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 14px;
  line-height: 19px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
}
th {
  height: 70px;
  padding-bottom: 20px;
  cursor: pointer;
}
tbody {
  position: relative;
}

.deleteBasket {
  cursor: pointer;
  right: 0;
}
.tableWithButtonWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tableButtonWrap {
  display: flex;
  justify-content: flex-end;
  margin-left: 16px;
}

.searchForm {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.searchForm input {
  padding-left: 40px;
}
.searchIcon {
  position: absolute;
  left: 8px;
  cursor: pointer;
}
.selectTh {
  position: absolute;
}
.adminDataWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.adminData {
  font-family: CourierPrime-Bold, sans-serif;
  font-size: 24px;
}

.innerVideoForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.innerVideoTitle {
  display: flex;
  align-items: center;
  margin: 90px auto 10px;
  width: 100%;
  max-width: 800px;
}

.innerVideoTitle .btnChangeTitleVideo {
  margin: 0 0 0 10px;
  max-width: 200px;
}

.styleTextVideo {
  width: 100%;
  max-width: 800px;
}

.isEmptyVideo {
  max-height: 385px;
}

.innerVideoForm > video {
  width: 100%;
  max-width: 800px;
  border-radius: 20px;
}

.innerVideoForm .recipeUploadWrap {
  max-width: 800px;
  margin-top: 40px;
}

.saveVideoButton {
  max-width: 800px;
}

.innerFormChangeDate {
  font-family: 'CourierPrime-Regular', sans-serif;
  font-size: 24px;
  line-height: 26px;
  display: flex;
  align-items: flex-end;
}

.tableUsersSubscribe {
  max-width: 300px;
  margin: 0 auto 25px;
}

.formChangeDate {
  display: flex;
  max-width: 400px;
  width: 100%;
  margin-left: 25px;
}

.datePicker {
  font-size: 24px;
  line-height: 36px;
  cursor: text;
}

.datePicker::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.innerFormChangeDate .buttonChangeDate {
  margin-top: 0;
  margin-left: 20px;
  max-width: 183px;
}

/*PAGINATION*/

.innerItemNumberPagination {
  min-width: 30px;
  height: 30px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 10px 10px 25px rgba(32, 223, 127, 0.1);
  border-radius: 20px;
  transition: box-shadow 0.5s;
}

.paginateBlock {
  display: flex;
  list-style: none;
  max-width: max-content;
  margin: 30px auto 0;
}

.itemNumberPagination {
  font-weight: 500;
  padding: 6px;
  width: 100%;
  text-align: center;
  color: #000000;
  transition: all 0.5s;
}

.itemNumberPagination:hover {
  color: #000000;
}

.innerItemNumberPagination:not(.activePagination):hover {
  color: #000000;
  -webkit-box-shadow: 0 0 25px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 25px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 25px -6px rgba(0, 0, 0, 0.75);
}

.activePagination {
  background-color: #37190c;
  border-color: #37190c;
  border-radius: 20px;
}

.activePagination > a {
  color: #fff;
  transition: all 0s;
}

.activePagination > a:hover {
  color: #ffffff;
  text-decoration: none !important;
}

.breakDots {
  min-width: 30px;
  height: 30px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 10px 10px 25px rgba(32, 223, 127, 0.1);
  border-radius: 20px;
}

.breakDots > a {
  font-size: 20px;
  margin: 0 10px;
}

.arrowBtnPagination {
  background: #ffffff;
  box-shadow: 10px 10px 25px rgba(32, 223, 127, 0.1);
  border-radius: 20px;
  line-height: 30px;
  margin: 0 8px;
  transition: all 0.5s;
}

.arrowBtnPagination:hover {
  color: #000000;
  -webkit-box-shadow: 0 0 25px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 25px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 25px -6px rgba(0, 0, 0, 0.75);
}

.arrowBtnPagination > a {
  color: #000000;
  text-align: center;
  padding: 0 10px;
}

.arrowBtnPagination:first-letter {
  text-transform: uppercase;
}

.arrowBtnPagination > a {
  font-weight: 500;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.disabledArrow {
  visibility: hidden;
}

/*PAGINATION*/
