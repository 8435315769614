.dropHeader,
.dropHeaderSmall {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: #fff;
  color: #e0e0e0;
  border-radius: 5px;
  border: 3px solid #e0e0e0;
  height: 53px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 24px;
  line-height: 36px;
  -webkit-transition: 0.04s ease-in-out;
  transition: 0.04s ease-in-out;
  cursor: pointer;
}
.selectedCheckbox {
  border-color: rgb(178, 178, 178);
  color: rgb(98, 98, 98);
}
.dropHeaderSmall {
  /*width: 140px;*/
  padding-left: 5px;
}
.dropdownArrow,
.dropdownArrowSmall {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 23px;
}

.dropdownArrowSmall {
  right: 12px;
}

.ingredientsWrap {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}
.ingredientsWrap:first-child .innerDeleteIngredient {
  visibility: hidden;
}
.hideButtonRemove {
  visibility: hidden;
}
.ingredientsNumber {
  margin-right: 12px;
  width: 127px;
}

.ingredientsName {
  width: 326px;
  margin-right: 12px;
}

.stepTitle {
  width: 100px;
  text-align: right;
  font-family: Rubik-Bold, sans-serif;
  font-size: 48px;
}
.wrapDropTbl {
  width: 160px;
  margin-right: 12px;
}

.wrapDropDoze.wrapDropDoze {
  display: inline-block;
  width: 153px;
  margin: 0 12px;
  padding-left: 9px;
  font-family: CourierPrime-Regular, sans-serif;
  font-size: 24px;
  line-height: 36px;
  -webkit-transition: 0.04s ease-in-out;
  transition: 0.04s ease-in-out;
  cursor: pointer;
}

.ingredientsMeasurement .inputRecipeNumber {
  width: 100%;
}

.ingredientsAmount {
  width: 189px;
}

.stepWrap {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  flex-direction: row;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .hideButtonRemove {
    display: none;
  }
  .ingredientsWrap:first-child {
    margin-right: 0;
  }
  .stepWrap {
    width: 100%;
  }
  .wrapDropDoze.wrapDropDoze {
    margin-right: 0;
  }
  .ingredientsWrap:first-child .innerDeleteIngredient {
    display: none;
  }
  .ingredientsWrap:first-child .ingredientsName {
    margin-right: 0;
  }
  .wrapDropTbl {
    width: 50%;
    margin-right: 0;
  }
  .dropHeaderSmall {
    width: 100%;
  }
  .ingredientsName {
    width: 100%;
  }
  .ingredientsNumber {
    width: 50%;
  }
  .ingredientsAmount {
    width: 100%;
  }
  .stepTitle {
    width: 50px;
    margin-top: -15px;
  }
  .stepWrap {
    align-items: flex-start;
  }
  .stepTitle {
    font-size: 24px;
  }
}
