.formSignUpLabel {
  font-family: CourierPrime-Regular, sans-serif;
  color: #37190c;
  font-size: 14px;
  line-height: 19px;
}
.formSignUpGroup {
  position: relative;
}
.form-label {
  margin-bottom: 0;
}
.form-control {
  font-family: CourierPrime-Regular, sans-serif;
  color: #37190c;
  font-size: 18px;
  border: 3px solid #e0e0e0;
  height: 52px;
}
.inputIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.iconPlaceHolder {
  padding-left: 41px;
}
.inputContent {
  position: relative;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: white;
}
.famItemWrap {
  width: 100%;
}
.innerButtonChanges {
  font-family: 'CourierPrime-Regular';
  font-size: 46px;
  line-height: 51px;
}
.famInput {
  margin-top: 16px;
  width: 100%;
}

@media (max-width: 991px) {
  .innerButtonChanges {
    line-height: 40px;
  }
}
