.loadBtn {
  text-align: center;
  border-radius: 13px;
  height: 74px;
  max-width: 256px;
  margin: 58px auto 0;
  background-color: transparent;
  border: 5px solid #893f1d;
  color: #893f1d;
}
.loadBtn:hover {
  background-color: transparent;
  color: #893f1d;
}
.loadBtnText {
  font-family: 'Rubik-Bold';
  font-size: 24px;
  line-height: 43px;
}
