.listUserGroup {
  max-height: 200px;
}

.headerOrderPurchase{
  margin-top: 102px;
  margin-bottom: 60px;
  text-align: center;
}

.titleOrder{
  margin-bottom: 10px;
}

.descriptionOrder{
  font-size: 16px;
  line-height: 18px;
  max-width: 700px;
  margin: 0 auto;
  color: #828282;
}

.inputNameCategory {
  font-size: 30px;
}

.innerSelector {
  font-family: 'CourierPrime-Regular', sans-serif;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: fit-content;
  margin: 10px auto 0;
}

@media (max-width: 991px) {
  .headerOrderPurchase{
    margin-bottom: 10px;
    margin-top: 35px;
  }
  .descriptionOrder{
    font-size: 14px;
  }
  .inputNameCategory {
    font-size: 24px;
    line-height: 30px;
    height: auto;
  }
  .innerSelector {
    font-size: 16px;
    gap: 5px;
    margin-top: 5px;
  }
  .innerSelectorCategory{
    flex-direction: column;
  }
}
