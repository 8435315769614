.innerUploadedFile {
  width: 100%;
}

.formPhotoRecipe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.5s;
  border-radius: 5px;
}

.formPhotoRecipe[alt] {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.inputFilePhoto {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.recipeUploadWrap:hover {
  background-color: rgba(241, 240, 240, 0.4);
}

.recipeButtonUpload {
  transition: all 0.5s;
}

.recipeUploadWrap:hover .formPhotoRecipe {
  z-index: -1;
  opacity: 0.3;
}

.hideBtnUpload .recipeButtonUpload {
  visibility: hidden;
  opacity: 0;
}

.hideBtnUpload:hover .recipeButtonUpload {
  visibility: visible;
  opacity: 1;
}

.showDefaultUploadButton .recipeButtonUpload{
  visibility: visible;
  opacity: 1;
}

.showDefaultUploadButton .formPhotoRecipe {
  z-index: -1;
  opacity: 0.3;
}

.showDefaultUploadButton.recipeUploadWrap {
  background-color: rgba(241, 240, 240, 0.4);
}

.innerImageSettings {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.errorBigImage {
  font-family: 'CourierPrime-Regular', sans-serif;
  font-size: 18px;
  color: red;
}

.removeImageBtn {
  display: block;
  max-width: 120px;
  margin-left: auto;
  padding: 10px 0;
  width: 100%;
}
